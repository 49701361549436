<script setup lang="ts">
import type { SuggestedCampaignDto } from "~~/_api_generated_";
import { prepareCurrencies, formatNumberUSA } from "~/utils/currencies";
import { smartTruncate } from "~/utils/stringHelpers";

interface Props {
  campaign: SuggestedCampaignDto;
  inModal?: boolean;
  landingPageMode?: boolean;
  customTotalBudgetText?: string;
  isDark?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inModal: false,
  landingPageMode: true,
  isDark: false,
  customTotalBudgetText: "Total Budget",
});

const emit = defineEmits(["openSidebar"]);

const getDesriptionHtmlIfExists = computed(() => {
  if ("descriptionHtml" in props.campaign) {
    return props.campaign.descriptionHtml;
  }
  return "";
});

const isEligible = computed(() => {
  return (
    props.campaign.eligibility.state === "Eligible" ||
    props.campaign.eligibility.state === "EligibleBasic"
  );
});

const shouldSeeLock = computed(() => {
  if (
    props.campaign.eligibility.state === "Evaluating" &&
    props.campaign.eligibility.lastNonEligibleWalletCaipAddress !== undefined
  ) {
    return true;
  }

  return (
    props.campaign.eligibility.state === "NotInvited" ||
    props.campaign.eligibility.state === "NotEligible" ||
    props.campaign.eligibility.state === "NotEligibleBasic"
  );
});

function openSidebar(seeCriteria: boolean = false) {
  globalEmit("campaignForSidebarClicked", {
    campaignToOpen: props.campaign,
    seeCriteria: seeCriteria,
  });
}
</script>
<template>
  <div
    class="grid group grid-cols-1 lg:grid-cols-3 lg:min-h-[170px] flex-wrap lg:flex-nowrap items-center justify-between gap-8 rounded-2xl p-6 pt-5 font-semibold"
  >
    <div class="col-span-2">
      <div class="flex flex-wrap items-center gap-2">
        <div class="text-lg">{{ campaign.title }}</div>
      </div>
      <div
        class="my-4 line-clamp-2"
        v-html="smartTruncate(getDesriptionHtmlIfExists, 150)"
      ></div>
      <CampaignFooter
        :class="[isDark ? 'text-white/50' : '']"
        :campaign="campaign"
        :landingPageMode="landingPageMode"
        :theme="campaign.signupBonus > 0 ? 'light' : 'dark'"
      />
    </div>
    <div v-if="campaign.apyBonus > 0 && !shouldSeeLock" class="pr-12">
      <div class="">Bonus Yield</div>
      <div
        v-if="campaign.apyBonus > 0"
        class="flex gap-2 items-end my-4 text-5xl font-bold"
      >
        +{{ campaign.apyBonus }}%
        <span class="text-2xl uppercase">APY</span>
      </div>
      <div class="text-black/50">Up to a Month for Verified Wallets</div>
    </div>

    <div v-if="landingPageMode && campaign.apyBonus > 0">
      <div class="">Bonus Yield</div>
      <div
        v-if="campaign.apyBonus > 0"
        class="flex gap-2 items-end my-4 text-5xl font-bold"
      >
        +{{ campaign.apyBonus }}%
        <span class="text-2xl uppercase">APY</span>
      </div>
      <div class="text-black/50">Up to a Month for Verified Wallets</div>
    </div>

    <div v-if="landingPageMode && !campaign.apyBonus">
      <div class="">{{ customTotalBudgetText }}</div>
      <div
        v-if="campaign.totalBudget"
        class="flex gap-2 items-end my-4 text-5xl font-bold"
      >
        {{ formatNumberUSA(campaign.totalBudget.amount) }}
        <span class="text-2xl uppercase">{{
          campaign.totalBudget.currency
        }}</span>
      </div>
      <div
        v-if="typeof campaign.totalBudget.estimatedValueUsd !== 'string'"
        :class="[isDark ? 'text-white/50' : 'text-black/50']"
      >
        Approx. {{ prepareCurrencies(campaign.totalBudget)["USD"] }}
      </div>
      <div
        v-if="typeof campaign.totalBudget.estimatedValueUsd === 'string'"
        class="text-black/50"
      >
        Token Generation Event in 2025
      </div>
    </div>

    <div
      v-if="
        !landingPageMode &&
        !campaign.apyBonus &&
        (isEligible ||
          campaign.eligibility.state === 'EvaluationPending' ||
          campaign.eligibility.state === 'Evaluating') &&
        campaign.totalBudget
      "
      class="pr-12"
    >
      <div class="">Total Budget</div>
      <div class="flex gap-2 items-end my-4 text-5xl font-bold">
        {{ formatNumberUSA(campaign.totalBudget.amount) }}
        <span class="text-2xl uppercase">{{
          campaign.totalBudget.currency
        }}</span>
      </div>
      <div
        v-if="prepareCurrencies(campaign.totalBudget)['USD']"
        :class="[isDark ? 'text-white/50' : 'text-black/50']"
      >
        Approx. {{ prepareCurrencies(campaign.totalBudget)["USD"] }}
      </div>
    </div>
    <div v-if="!landingPageMode && shouldSeeLock" class="pr-12">
      <div class="flex gap-2 items-end text-5xl font-bold">
        <IconsLockIcon />
      </div>
      <div class="text-black/50 mt-4">
        You are not eligible to join this campaign.
        <button
          class="underline text-[#6659FF]"
          @click.stop="openSidebar(true)"
        >
          See criteria</button
        >.
      </div>
    </div>
  </div>
</template>
